.App {
  text-align: center;
}

.container {
  padding: 2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.canvas {
  height: 256px;
  width: 296px;
  border: 1px solid #ddd;
}

.vertical {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 256px;
}

.horizontal {
  width: 296px;
  margin-left: -30px;

}

.settings {
  color: white;
  font-family: "YurukaStd";
  margin: 1.5rem 0 1.5rem 0;
}

.settings > div {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.text {
  margin-bottom: 1rem;
}

.picker {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.modal > .MuiPopover-paper {
  overflow: hidden;
}

.picker-search {
  margin: 0.5rem;
}

.image-grid-wrapper {
  overflow: auto;
}

.image-grid {
  margin: 0.5rem;
  border-radius: 0.25rem;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
}

.footer {
  margin-top: 2rem;
  font-family: "YurukaStd";
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.footer > a {
  color: rgb(199, 199, 199);
  text-decoration: none;
  margin-bottom: 0.5rem;
}

.footer > a:hover {
  color: rgb(255, 255, 255);
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 1rem;
  min-height: 2rem;
}
